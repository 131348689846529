import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import * as jQuery from 'jquery';
import { AppComponent } from './app.component';
import { LoginModule } from './login/login.module';
import {AppRoutesModule} from './app.routes';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpModule } from '@angular/http';
import { BootstrapModalModule } from 'ng2-bootstrap-modal';
import {DeletPoupComponrnt} from '../app/DeletPoup/delete.component';
import {AuthGuardfor} from '../app/service/authGuard.service';
import {AuthService} from '../app/service/auth.service';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { LayoutModule } from '@angular/cdk/layout';
import { Ng2DeviceDetectorModule } from 'ng2-device-detector';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { ModalDelComponent } from './delUser/modal-del/modal-del.component';
import { ConfirmPopupComponent } from './confirm-popup/confirm-popup.component';
import { HttpClientModule } from '@angular/common/http';


 

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule, 
    ReactiveFormsModule,
    AppRoutesModule,
    LayoutModule,
    LoginModule,HttpModule,SimpleNotificationsModule.forRoot(),
    Ng2DeviceDetectorModule.forRoot(),
    BootstrapModalModule.forRoot({container:document.body}),
    Ng2TelInputModule,
    HttpClientModule,
    ],
  declarations: 
  [
    AppComponent,DeletPoupComponrnt, ModalDelComponent, ConfirmPopupComponent, 
  ],
  entryComponents: 
  [
    DeletPoupComponrnt,ModalDelComponent, ConfirmPopupComponent
  ],
  bootstrap: [AppComponent],
  providers:[AuthService,AuthGuardfor]
})
export class AppModule {
  constructor() {
    console.log  =function(){};
    console.error  =function(){};
    window.alert = function() {};
  }
 }
