import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root', // Makes it available throughout the app
})
export class LanguageService {
  constructor(private router: Router) {}

  // Redirect to the default language
  redirectToDefaultLang(defaultLang: string) {
    this.router.navigate([], {
      queryParams: { lang: defaultLang }, // Set the default language in the URL
      queryParamsHandling: 'merge', // Merge with existing query params
    });
  }
}
