import { Component, OnInit ,ViewEncapsulation} from '@angular/core';
import {CommanHelperService} from '../service/commanHelper.service';
import { DialogComponent, DialogService } from "ng2-bootstrap-modal";
import { ActivatedRoute, Router } from '@angular/router';
export interface ConfirmModel {
    title:string;
    message:string;
    okBtn?:string;
    isAvailable?:boolean;
    okAvailable?:boolean;
    showLoginRoute?:boolean;
  }
  
@Component({
    selector:'deletePoup',
    templateUrl:'../DeletPoup/delete.component.html',
    styleUrls:['../DeletPoup/delete.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class DeletPoupComponrnt extends DialogComponent<ConfirmModel, boolean> implements ConfirmModel
{
    title: string = '';
    message: string = '';
    okBtn: string = 'OK';
    cancelBtn:any= 'Cancel';
    isAvailable:boolean=true;
    okAvailable:boolean=true;
    showLoginRoute:boolean=false;
   
    
    constructor(public dialogService: DialogService,private router: Router, private route: ActivatedRoute)
    {
        super(dialogService);   
        this.route.queryParams.subscribe((params) => {
            let lang ;
            lang = params['lang'];
            if(lang=='es'){
                this.cancelBtn='Cancelar';
            }else{
                this.cancelBtn='Cancel';
            }
          });
    }

    confirm()
    {
        this.result = true;
        this.close();
    }

    cancel()
    {
        this.result = false;
        this.close();
    }
    routeBtn(){
        let Toroute=window.location.origin
        setTimeout(() => {
            window.location.href = Toroute;
        }, 200);

    }






}
